import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useMainContext } from "./../../../contexts/MainContext";
import * as Alerts from "./../../../utils/Alerts";
import * as reviewService from "./../../../services/ReviewService";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import CommitButton from "./../../../components/commitButton/Index";
import Select from "./../../../components/select/Index";
import StudentSelectModal from "./../../student/modal/SelectModal";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";
import { useStudent } from "../../../helper/useStudent";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [review, setReview] = useState({});
  const [reviewtypes, setReviewTypes] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isOpenStudentSelectModal, setIsOpenStudentSelectModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);

  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();
  const { idReview } = useParams();

  useEffect(() => {
    getReviewTypes();
    idReview && getReview();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getReview = async () => {
    let result = await reviewService.getReview(idReview);
    if (result.success) {
      setReview(result.data);
      setSelectedStudent({ rowId: result?.data?.fkIdStudent, fullName: result?.data?.studentFullName });
      setSelectedEmployee({ rowId: result?.data?.fkIdEmployee, fullName: result?.data?.employeeFullName });
     
    } else {
      Alerts.error(result.message);
    }
  };

  const getReviewTypes = async () => {
    let result = await reviewService.getReviewTypes();
    if (result.success) {
      setReviewTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateReview = async () => {
    review.fkIdStudent = selectedStudent?.rowId;
    review.fkIdEmployee = selectedEmployee?.rowId;
    let result = idReview ? await reviewService.updateReview({ ...review, fkIdBranch: localStorage.branch }) : await reviewService.addReview({ ...review, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/review`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setReview({ ...review, [name]: e.value });
  };  

  const handleStudentSelectModal = () => {
    setIsOpenStudentSelectModal(!isOpenStudentSelectModal);
  };
  
  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };


  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">comment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idReview ? (isView ? "Rəy məlumatları" : "Rəy məlumatlarının redaktəsi") : "Yeni Rəy"}</h4>

                  <div className="row">

                  <>
                    <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={"Tələbənin tam adı"} value={selectedStudent != null ? selectedStudent.fullName : ""} disabled={true} />
                    {!isView && (
                    <div className="form-group col-sm-5">
                      <label className="label-control w-100"></label>
                      {selectedStudent != null ? (
                        <button className="btn btn-danger" disabled={isView} onClick={() => setSelectedStudent(null)}>
                          <span class="material-icons">autorenew</span>
                        </button>
                      ) : null}
                      <button className="btn btn-info" disabled={isView} onClick={handleStudentSelectModal}>
                        Tələbə seç
                      </button>
                    </div>
                    )}
                  </>

                  <>
                      <FormInput style={isView ? "col-sm-12" : "col-sm-7"} required={true} label={"Təlimçinin tam adı"} value={selectedEmployee != null ? selectedEmployee?.fullName : ""} disabled={true} />
                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedEmployee != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                            {"Təlimçi seç"}
                          </button>
                        </div>
                      )}
                    </>

                  <Select placeholder={"Seç"} style={"col-sm-6"} label={"Rəyin növü"} selectedValue={review?.fkIdReviewType} changeSelectHandler={(e) => handleSelect(e, "fkIdReviewType")} options={reviewtypes.map((reviewtype) => ({ label: reviewtype?.description, value: reviewtype?.idReviewType }))} isDisabled={isView} />
                    <FormInput style={"col-sm-6"} label={"Başlıq"} name={"title"} required={true} value={review?.title} onChange={handleInput} disabled={isView} />
                    <FormInput style={"col-sm-12"} ro label={"Rəy"} name={"review1"} value={review?.review1} onChange={handleInput} disabled={isView} />
                    <FormCheckbox label={"Ümumi rəy"} style={"col-sm-12"} name={"isPublic"} checked={review?.isPublic} onChange={() => setReview({ ...review, isPublic: review?.isPublic == 1 ? 0 : 1 })} disabled={isView} />

                   </div>

                  <div className="row align-right">
                    {!isView && (
                      <CommitButton onClick={addOrUpdateReview} />
                    )}
                    <Link to={`${alias}${id}/review`}>
                      <button type="submit" className="btn btn-fill btn-danger">
                        Bağla
                      </button>
                    </Link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenStudentSelectModal ? <StudentSelectModal isOpen={isOpenStudentSelectModal} handleStudentSelectModal={handleStudentSelectModal} setSelectedStudent={setSelectedStudent} /> : null}
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} /> : null}
    </div>
  );
}
