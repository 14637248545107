import { get, post, put, deleteData } from "./core/Requests";

export const getReviews = async (pageIndex = 0, pageSize = 0) => {
  return await get(`/Review`, { pageIndex, pageSize });
};

export const getReviewsByBranch = async (pageIndex, pageSize, branchId) => {
  return await get(`/Review/getByBranch/${branchId}`, {
    pageIndex,
    pageSize,
  });
};

export const getReviewsByBranchAndFilter = async (pageIndex, pageSize, branchId, filter) => {
  return await post(`/Review/getList/${branchId}`,filter, {
    pageIndex,
    pageSize,
  });
};


// export const getReviewsByOrganization = async (pageIndex, pageSize) => {
//   return await get(`/Review/getByOrganization`, {
//     pageIndex,
//     pageSize,
//   });
// };

export const getReviewTypes = async (id) => {
  return await get(`/Helper/GetReviewTypes`);
};

export const getReview = async (id) => {
  return await get(`/Review/${id}`);
};

export const getForSelect = async (branchId) => {
  return await get(`/Review/GetForSelect/${branchId}`);
};

export const addReview = async (data) => {
  return await post(`/Review`, data);
};

export const updateReview = async (data) => {
  return await put(`/Review`, data);
};

export const deleteReview = async (id) => {
  return await deleteData(`/Review/${id}`);
};

export const changeActive = async (id) => {
  return await get(`/Review/changeActive/${id}`);
};
