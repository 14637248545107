import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as userService from "./../../services/UserService";
import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import UserChangePasswordModal from "./modal/ChangePasswordModal";
import { useTokenData } from "../../helper/useTokenData";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [users, setUsers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ common: null });

  const alias = useOrganization();
  const tokenData = useTokenData();

  const handleChangePasswordModal = () => {
    if (isOpenChangePasswordModal) setSelectedRow(0);
    setIsOpenChangePasswordModal(!isOpenChangePasswordModal);
  };

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getUsers(state.currentPage, localStorage.rowCount);
  }, []);

  const getUsers = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = tokenData.isSysUser == "1" ? await userService.getUsers(pageIndex, pageSize, filter) : await userService.getUsersByOrganization(pageIndex, pageSize, filter);
    if (result.success) {
      setUsers(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteUser = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await userService.deleteUser(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getUsers(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getUsers(pageIndex, localStorage.rowCount);
  };

  const changeActive = async (idUser) => {
    let result = await userService.changeActive(idUser);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getUsers(pagination.pageIndex, localStorage.rowCount);
  };


  const Search = (e) => {
    e.preventDefault();
    getUsers(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`user/delete`}
      editPath={`user/edit`} 
      addPath={`user/add`} deleteRow={deleteUser}
      changePasswordModal={setIsOpenChangePasswordModal}
      isChangePassword={isOpenChangePasswordModal}
       selectedRow={selectedRow} /> 

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">people_alt</i>
        </div>
        <div className="card-content">
        <h4 className="card-title">İstifadəçilərin siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e)=> getUsers(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button
                  className="btn btn-white btn-round btn-just-icon"
                  type="submit"
                  //onClick={() => getEmployees(PAGE_INDEX, localStorage.rowCount)}
                >
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : users?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>İstifadəçi adı</th>
                    {tokenData.isSysUser == "1" ? <th>Təşkilat</th> : null}
                    <th>Email</th>
                    <th>Əlaqə nömrəsi</th>
                    <th>İstifadəçi növü</th>
                    <th>Aktivlik</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item, i) => (
                    <tr key={item?.idUser} onClick={() => isSelectRow(item?.idUser)} className={selectedRow === item.idUser ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.userName}</td>
                      {tokenData.isSysUser == "1" ? <td>{item?.organizationName}</td> : null}
                      <td>{item?.emailAddress}</td>
                      <td>{item?.phoneNumber}</td>
                      <td>{item?.userType}</td>
                      <td>
                        <div className="togglebutton">
                          <label>
                            <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idUser)} />
                            <span className="toggle" />
                          </label>
                        </div>
                      </td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/user/view/${item?.idUser}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                        <Link to={`${alias}/user/privilage/${item?.idUser}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">lock</i>
                          </button>
                        </Link>
                        <Link to={`${alias}/user/branchPrivilage/${item?.idUser}/${item?.fkIdOrganization}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">domain</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>

      {isOpenChangePasswordModal ? <UserChangePasswordModal isOpen={isOpenChangePasswordModal} handleChangePasswordModal={handleChangePasswordModal} id={selectedRow} /> : null}
    </div>
  );
}
