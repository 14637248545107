import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as applicationService from "../../services/ApplicationService";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import * as date from "../../utils/DateNow";
import { useTokenData } from "../../helper/useTokenData";
import ActionButtons from "./../../components/actionButton/Index";
import { URL } from "./../../services/core/Urls";
import * as UserTypes from "./../../constants/UserType";

export default function Index() {
  const { state, setState } = useMainContext();
  const [applications, setApplications] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });
  const [isOpenCombo, setIsOpenCombo] = useState(false);

  const alias = useOrganization();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    setFilter({ startDate: date.Now(-30), endDate: date.Now() });
    getApplications(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getApplications = async (pageIndex, pageSize) => {
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await applicationService.getApplicationsByBranch(localStorage.branch, pageIndex, pageSize, filter);
      if (result.success) {
        setApplications(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const deleteApplication = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await applicationService.deleteApplication(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getApplications(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeConvertToContract = async (idApplication) => {

    await Alerts.confirmForChange(async function (confirmed) {
      if (confirmed) {
        let result = await applicationService.changeConvertToContract(idApplication);
        if (result.success) {
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getApplications(pagination.pageIndex, localStorage.rowCount);
      }
    });


  };

  const Search = (e) => {
    e.preventDefault();
    getApplications(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getApplications(pageIndex, localStorage.rowCount);
  };

  const exportExcel = async () => {
    var resp = await applicationService.exportExcel(localStorage.branch, filter);
    var data = new Blob([resp]);
    window.location = URL.createObjectURL(data);
  };


  return (
    <div className="col-md-12">
      <label className="f-right">
        <div className={`dropdown ${isOpenCombo && "open"}`}>
          <button onClick={() => setIsOpenCombo(!isOpenCombo)} className="dropdown-toggle btn btn-success" data-toggle="dropdown">
            Digər <b className="caret" />
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <li>
              <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                <a style={{ color: "white" }} onClick={() => (window.location = URL.BaseURL + `/application/export/${localStorage.branch}`)}>
                  Export
                </a>
              </label>
            </li>
          </ul>
        </div>
      </label>
      {tokenData.userTypeId != UserTypes.STUDENT_USER && <ActionButtons deletePath={`application/delete`} editPath={`application/edit`} addPath={`application/add`} deleteRow={deleteApplication} selectedRow={selectedRow} />}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assignment_ind</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Müraciətlərin siyahısı</h4>

          <div className="row">
            <div className="col-md-4 f-left">
              <PageSizeSelect onChange={(e) => getApplications(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : applications?.length > 0 ? (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Tam adı</th>
                      <th>Tarix</th>
                      <th>Müraciət forması</th>
                      <th>Referans</th>
                      <th>Maraqlanılan kurs</th>
                      <th>Uğur ehtimalı</th>
                      <th>Uğurlu olub olmaması</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {applications.map((item, i) => (
                      <tr key={item?.idApplication} onClick={() => isSelectRow(item?.idApplication)} className={selectedRow === item?.idApplication ? "selectedRow" : ""}>
                        <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                        <td>{item?.fullName}</td>
                        <td>{item?.applicationDate}</td>
                        <td>{item?.applicationType}</td>
                        <td>{item?.referance}</td>
                        <td>{item?.trainingType}</td>
                        <td>{item?.probability} %</td>
                        <td>
                          <div className="togglebutton">
                            <label>
                              <input type="checkbox" checked={item?.convertedToContract} onChange={() => changeConvertToContract(item?.idApplication)} />
                              <span className="toggle" />
                            </label>
                          </div>
                        </td>
                        <td className="td-actions text-right">
                          <Link to={`${alias}/application/view/${item?.idApplication}`}>
                            <button className="btn btn-info">
                              <i className="material-icons">visibility</i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
