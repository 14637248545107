import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import Pagination from "./../../../components/pagination/Index";
import * as reportService from "./../../../services/ReportService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";

import { Link, useParams } from "react-router-dom";
import * as Alerts from "./../../../utils/Alerts";
import { useOrganization } from "../../../helper/useOrganization";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import PageSizeSelect from "./../../../components/pageSizeSelect/Index";
import ReportTable from "../../../components/table/ReportTable";
import * as date from "./../../../utils/DateNow";
import * as caseService from "./../../../services/CaseService";
import Select from "./../../../components/select/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [caseReports, setCaseReports] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedCase, setSelectedCase] = useState({ rowId: 1 });
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });
  const [cases, setCases] = useState([]);

  const alias = useOrganization();
  const { idReport } = useParams();

  const headers = ["Kassaların ümumi hesabatı", "Kassanın günlər üzrə tam hesabatı", "Kassa mədaxil və məxaric hesabatı"];

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getCases();
    (idReport == 1 || filter.idCase) && getCaseReports(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch, filter.idCase]);

  const getCases = async () => {
    let result = await caseService.getCasesByBranch(0, 0, localStorage.branch);
    if (result.success) {
      setCases(result.data.datas);
    } else {
      Alerts.error(result.message);
    }
  };

  const getCaseReports = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await reportService.getCaseReportsByBranch(0, 0, idReport, { ...filter, idBranch: localStorage.branch });
    if (result.success) {
      setCaseReports(result?.data?.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const Search = (e) => {
    e.preventDefault();
    getCaseReports(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {idReport != 1 && (
        <Select
          placeholder={"Seç"}
          style={"col-sm-4 f-right z-100"}
          label={"Kassa"}
          changeSelectHandler={(e) => {
            setFilter({ ...filter, idCase: e.value });
          }}
          selectedValue={filter?.fkIdCase}
          options={cases?.map((c) => ({ label: c?.description, value: c?.idCase }))}
        />
      )}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">assessment</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">{headers[idReport - 1]}</h4>
          <div className="row">
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="table-responsive">
              <ReportTable data={caseReports} reportType={"case"} idReport={idReport} pagination={pagination} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
