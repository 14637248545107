import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as organizationService from "./../../../services/OrganizationService";
import * as branchService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import * as userTypeService from "./../../../services/UserTypeService";
import * as userService from "./../../../services/UserService";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "../../../helper/useOrganization";
import * as UserTypesEnum from "./../../../constants/UserType";
import { useTokenData } from "../../../helper/useTokenData";
import EmployeeSelectModal from "./../../employee/modal/SelectModal";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";

export default function Index() {
  const alias = useOrganization();
  const tokenData = useTokenData();
  const location = useLocation();
  const navigate = useNavigate();
  const { idUser } = useParams();

  const { state, setState } = useMainContext();
  const [user, setUser] = useState({});
  const [userTypes, setUserTypes] = useState([]);
  const [repeatPassword, setRepeatPassword] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [isOpenEmployeeSelectModal, setIsOpenEmployeeSelectModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isView, setIsView] = useState(false);

  const handleEmployeeSelectModal = () => {
    setIsOpenEmployeeSelectModal(!isOpenEmployeeSelectModal);
  };

  useEffect(() => {
    getUserTypes();
    if (tokenData.isSysUser == "1") getOrganizations();
    else getBranchs(0);
    idUser && getUser();
    if (location.pathname.includes("/view/")) setIsView(true);
  }, []);

  const getUser = async () => {
    let result = await userService.getUser(idUser);
    if (result.success) {
      setUser(result.data);
      getBranchs(result?.data?.fkIdOrganization);
      setSelectedEmployee({ rowId: result?.data?.fkIdEmployee, fullName: result?.data?.employeeFullName });
    } else {
      Alerts.error(result.message);
    }
  };

  const getUserTypes = async () => {
    let result = await userTypeService.getUserTypes();
    if (result.success) {
      setUserTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateUser = async () => {
    if (idUser || user.password === repeatPassword) {
      if (selectedEmployee != null) user.fkIdEmployee = selectedEmployee?.rowId;
      if (selectedStudent != null) user.fkIdStudent = selectedStudent?.rowId;
      let result = idUser ? await userService.updateUser(user) : await userService.addUser(user);
      if (result?.success) {
        Alerts.successWithBack(result.message, `${alias}/user`, navigate);
      } else {
        Alerts.error(result.message);
      }
    } else Alerts.error("Şifrə və təkrar şifrə uyğun deyil!");
  };

  const handleInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const getOrganizations = async () => {
    let orgs = await organizationService.getOrganizationForSelect();
    if (orgs.success) {
      setOrganizations(orgs.data);
    } else {
      Alerts.error(orgs.message);
    }
  };

  const getBranchs = async (organizationId) => {
    if (
      true
      // user?.fkIdUserType != null &&
      // user?.fkIdUserType != UserTypesEnum.ORGANIZATION_USER
    ) {
      let result = await branchService.getBranchsByOrganizationForSelect(organizationId);
      if (result?.success) {
        setBranchs(result?.data);
      } else {
        Alerts.error(result?.message);
      }
    }
  };

  const handleSelect = (e, name) => {
    setUser({ ...user, [name]: e.value });
  };

  return (
    <div class="content">
      {isOpenEmployeeSelectModal ? <EmployeeSelectModal isOpen={isOpenEmployeeSelectModal} handleEmployeeSelectModal={handleEmployeeSelectModal} setSelectedEmployee={setSelectedEmployee} organizationId={user?.fkIdOrganization} /> : null}
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idUser ? (isView ? "İstifadəçiyə baxış" : "İstifadəçinin redaktəsi") : "Yeni istifadəçi"}</h4>
                <div className="row">
                  <FormInput style={"col-sm-12"} label={"İstifadəçi adı"} name={"userName"} value={user?.userName} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Əlaqə nömrəsi"} name={"phoneNumber"} value={user?.phoneNumber} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Email"} name={"emailAddress"} value={user?.emailAddress} onChange={handleInput} disabled={isView} />
                  {!idUser && (
                    <>
                      <FormInput style={"col-sm-6"} label={"Şifrə"} name={"password"} type={"password"} value={user?.password} onChange={handleInput} disabled={isView} />
                      <FormInput style={"col-sm-6"} label={"Şifrə (təkrar)"} name={"passwordRepeat"} type={"password"} value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} disabled={isView} />
                    </>
                  )}
                  <Select
                    placeholder={"Seç"}
                    style={"col-sm-6"}
                    label={"İstifadəçinin növü"}
                    changeSelectHandler={(e) => {
                      handleSelect(e, "fkIdUserType");
                    }}
                    selectedValue={user?.fkIdUserType}
                    isDisabled={isView}
                    options={userTypes.map((item) => ({ label: item.description, value: item.idUserType }))}
                  />
                  {tokenData.isSysUser == "1" ? (
                    <Select
                      placeholder={"Seç"}
                      style={"col-sm-6"}
                      label={"Təşkilat adı"}
                      changeSelectHandler={(e) => {
                        handleSelect(e, "fkIdOrganization");
                        if (user?.fkIdUserType != UserTypesEnum.ORGANIZATION_USER) getBranchs(e.value);
                      }}
                      selectedValue={user?.fkIdOrganization}
                      isDisabled={isView}
                      options={organizations?.map((item) => ({
                        label: item.organizationName,
                        value: item.idOrganization,
                      }))}
                    />
                  ) : null}
                  {user?.fkIdUserType != UserTypesEnum.ORGANIZATION_USER && user?.fkIdUserType != null ? (
                    <Select
                      placeholder={"Seç"}
                      style={"col-sm-6"}
                      label={"Filialın adı"}
                      changeSelectHandler={(e) => {
                        handleSelect(e, "fkIdBranch");
                      }}
                      selectedValue={user?.fkIdBranch}
                      isDisabled={isView}
                      options={branchs.map((item) => ({ label: item.branchName, value: item.idBranch }))}
                    />
                  ) : null}
                  {(user?.fkIdUserType == UserTypesEnum.TRAINER_USER || user?.fkIdUserType == UserTypesEnum.STUDENT_USER) ? (
                    <>
                      <FormInput style={"col-sm-7"} label={"Əməkdaşın tam adı"} value={selectedEmployee != null ? selectedEmployee.fullName : ""} disabled={true} />

                      {!isView && (
                        <div className="form-group col-sm-5">
                          <label className="label-control w-100"></label>
                          {selectedEmployee != null ? (
                            <button className="btn btn-danger" onClick={() => setSelectedEmployee(null)}>
                              <span class="material-icons">autorenew</span>
                            </button>
                          ) : null}
                          <button className="btn btn-info" onClick={handleEmployeeSelectModal}>
                            Əməkdaş seç
                          </button>
                        </div>
                      )}
                    </>
                  ) : null}

                  <FormCheckbox label={"Aktivlik"} style={"col-sm-12"} name={"isActive"} checked={user?.isActive} onChange={() => setUser({ ...user, isActive: user.isActive == 1 ? 0 : 1 })} disabled={isView} />
                </div>

                <div className="row align-right">
                  {!isView && (
                   <CommitButton onClick={addOrUpdateUser} />
                  )}
                  <Link to={`${alias}/user`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
