import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as journalService from "./../../services/JournalService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { useTokenData } from "../../helper/useTokenData";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import * as date from "./../../utils/DateNow";
import ActionButtons from "./../../components/actionButton/Index";

export default function Index() {
  const { state, setState } = useMainContext();
  const [journals, setJournals] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ startDate: date.Now(), endDate: date.Now() });
  const alias = useOrganization();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  useEffect(() => {
    getJournals(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getJournals = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await journalService.getJournalsByBranch(pageIndex, pageSize, localStorage.branch, filter);
    if (result.success) {
      setJournals(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteJournal = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await journalService.deleteJournal(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getJournals(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getJournals(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getJournals(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons 
      deletePath={`journal/delete`}
      editPath={`journal/edit`} 
      addPath={`journal/add`} deleteRow={deleteJournal}
       selectedRow={selectedRow} />       
    
      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">list_alt</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Jurnalların siyahısı</h4>
          <div className="row">
            <div className="col-md-4 f-left">
            <PageSizeSelect onChange={(e) => getJournals(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />

                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : journals?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    {tokenData.isSysUser == "1" ? <th>Filial</th> : null}
                    <th>Qrup</th>
                    <th className="text-center">Təlimçi</th>
                    <th className="text-center">Assistent</th>
                    <th>Qeydiyyat tarixi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {journals.map((item, i) => (
                    <tr key={item?.idJournal} onClick={() => isSelectRow(item?.idJournal)} className={selectedRow === item?.idJournal ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      {tokenData.isSysUser == "1" ? <td>{item?.branchName}</td> : null}
                      <td>{item?.groupName}</td>
                      <td className="text-center">{item?.trainerParticipated == 1 ? <span class="material-icons color-green">done</span> : <span class="material-icons color-red">close</span>}</td>
                      <td className="text-center">{item?.assistantParticipated == 1 ? <span class="material-icons color-green">done</span> : <span class="material-icons color-red">close</span>}</td>
                      <td>{item?.registrationDate?.split("T")[0]}</td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}/journal/view/${item?.idJournal}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
