import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as groupTrainingPlanService from "./../../../services/GroupTrainingPlanService";
import * as groupService from "./../../../services/GroupService";
import * as branchService from "./../../../services/BranchService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormTextareaInput from "./../../../components/formTextareaInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import * as date from "./../../../utils/DateNow";
import { useStudent } from "../../../helper/useStudent";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [groupTrainingPlan, setGroupTrainingPlan] = useState({ assignmentDate: date.Now(), assignmentDeadline: date.Now(7) });
  const [groups, setGroups] = useState([]);

  const alias = useOrganization();
  const id = useStudent();
  const location = useLocation();
  const navigate = useNavigate();
  const { idGroupTrainingPlan } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idGroupTrainingPlan && getGroupTrainingPlan();
  }, []);

  useEffect(() => {
    getGroups();
  }, [localStorage.branch]);

  const getGroupTrainingPlan = async () => {
    let result = await groupTrainingPlanService.getGroupTrainingPlan(idGroupTrainingPlan);
    if (result.success) {
      setGroupTrainingPlan(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroups = async () => {
    let result = await groupService.getForSelect(localStorage.branch);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateGroupTrainingPlan = async () => {
    let result = idGroupTrainingPlan ? await groupTrainingPlanService.updateGroupTrainingPlan({ ...groupTrainingPlan, fkIdBranch: localStorage.branch }) : await groupTrainingPlanService.addGroupTrainingPlan({ ...groupTrainingPlan, fkIdBranch: localStorage.branch });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/groupTrainingPlan`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setGroupTrainingPlan({ ...groupTrainingPlan, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setGroupTrainingPlan({ ...groupTrainingPlan, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">app_registration</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idGroupTrainingPlan ? (isView ? "Təlim planı məlumatları" : "Təlim planı məlumatlarının redaktəsi") : "Yeni təlim planı"}</h4>

                <div className="row">
                  <Select placeholder={"Seç"} style={"col-sm-12"} label={"Qrup"} required={true} selectedValue={groupTrainingPlan?.fkIdGroup} changeSelectHandler={(e) => handleSelect(e, "fkIdGroup")} options={groups.map((group) => ({ label: group?.groupName, value: group?.idGroup }))} isDisabled={isView} />
                  <FormInput style={"col-sm-6"} label={"Başlıq"} name={"title"} required={true} value={groupTrainingPlan?.title} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-6"}  label={"Plan tarixi"} required={true} name={"planDate"} type={"date"} value={groupTrainingPlan?.planDate} onChange={handleInput} disabled={isView} />
                  <FormTextareaInput style={"col-sm-12"} rows={5} label={"Məzmun"} name={"description"} value={groupTrainingPlan?.description} onChange={handleInput} disabled={isView} />
                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateGroupTrainingPlan} />}
                  <Link to={`${alias}${id}/groupTrainingPlan`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
