import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as employeeService from "../../services/EmployeeService";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import { Link } from "react-router-dom";
import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import FinanceOperationListModal from "../financeOperation/modal/ListModal";
import ActionButtons from "../../components/actionButton/Index";
import ContractTrainerListByTrainerModal from "./../contract/trainer/modal/ContractTrainerListByTrainerModal";
import GroupListForTrainerModal from "./../group/modal/GroupListForTrainerModal";
import AttendanceListForTrainerModal from "./../journal/modal/AttendanceListForTrainerModal";
import TrainerDebtModal from "./modal/TrainerDebtListModal";
import { URL } from "./../../services/core/Urls";

export default function Index() {
  const { state, setState } = useMainContext();
  const [employees, setEmployees] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });
  const [loading, setLoading] = useState(false);
  const [isOpenFinanceOperationListModal, setIsOpenFinanceOperationListModal] = useState(false);
  const [isContractTrainerListByTrainerModal, setIsContractTrainerListByTrainerModal] = useState(false);
  const [isOpenGroupListModal, setIsOpenGroupListModal] = useState(false);
  const [isOpenAttendanceListModal, setIsAttendanceListModal] = useState(false);
  const [isOpenTrainerDebtListModal, setIsTrainerDebtListModal] = useState(false);

  const alias = useOrganization();

  const handleContractTrainerListByTrainerModal = (e) => {
    e.stopPropagation();
    if (isContractTrainerListByTrainerModal) setSelectedRow(0);
    setIsContractTrainerListByTrainerModal(!isContractTrainerListByTrainerModal);
  };

  const handleGroupListModal = (e) => {
    e.stopPropagation();
    if (isOpenGroupListModal) setSelectedRow(0);
    setIsOpenGroupListModal(!isOpenGroupListModal);
  };

  const handleAttendanceListModal = (e) => {
    e.stopPropagation();
    if (isOpenAttendanceListModal) setSelectedRow(0);
    setIsAttendanceListModal(!isOpenAttendanceListModal);
  };

  const handleTrainerDebtListModal = (e) => {
    e.stopPropagation();
    if (isOpenTrainerDebtListModal) setSelectedRow(0);
    setIsTrainerDebtListModal(!isOpenTrainerDebtListModal);
  };

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleFinanceOperationListModal = (e) => {
    e.stopPropagation();
    if (isOpenFinanceOperationListModal) setSelectedRow(0);
    setIsOpenFinanceOperationListModal(!isOpenFinanceOperationListModal);
  };

  useEffect(() => {
    getEmployees(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getEmployees = async (pageIndex, pageSize) => {
    setLoading(true);
    //   let result = await employeeService.getEmployees(pageIndex, pageSize, filter);
    let result = await employeeService.getTrainersByBranch(pageIndex, pageSize, localStorage.branch, filter);
    if (result.success) {
      setEmployees(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteEmployee = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await employeeService.deleteEmployee(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getEmployees(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getEmployees(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getEmployees(PAGE_INDEX, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      <ActionButtons deletePath={`trainer/delete`} editPath={`trainer/edit`} addPath={`trainer/add`} deleteRow={deleteEmployee} selectedRow={selectedRow} />

      <button className="btn btn-success f-right" onClick={() => (window.location = URL.BaseURL + `/employee/trainer/export/${localStorage.branch}`)}>
        <i className="material-icons">download</i>
        Export
      </button>

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">school</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Təlimçilərin siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getEmployees(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>
          {loading ? (
            <Loading />
          ) : employees?.length > 0 ? (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Tam adı</th>
                      <th>Əlaqə nömrəsi</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((item, i) => (
                      <tr key={item?.idEmployee} onClick={() => isSelectRow(item?.idEmployee)} className={selectedRow === item?.idEmployee ? "selectedRow" : ""}>
                        <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                        <td>{item?.surname + " " + item?.name + " " + item?.middleName}</td>
                        <td>{item?.phoneNumber}</td>
                        <td>{item?.emailAddress}</td>
                        <td className="td-actions text-right">
                          {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "financeoperation/4") ? (
                            <button
                              title="Ödənişlər"
                              className="btn btn-success"
                              onClick={(e) => {
                                setSelectedRow(item?.idEmployee);
                                handleFinanceOperationListModal(e);
                              }}
                            >
                              <i className="material-icons">paid</i>
                            </button>
                          ) : null}
                          <button
                            title="Qruplar"
                            className="btn btn-secondary"
                            onClick={(e) => {
                              setSelectedRow(item?.idEmployee);
                              handleGroupListModal(e);
                            }}
                          >
                            <i className="material-icons">groups</i>
                          </button>
                          <button
                            title="Borclar"
                            className="btn btn-danger"
                            onClick={(e) => {
                              setSelectedRow(item?.idEmployee);
                              handleTrainerDebtListModal(e);
                            }}
                          >
                            <i className="material-icons">payments</i>
                          </button>
                          <button
                            title="Müqavilələr"
                            className="btn open-blue"
                            onClick={(e) => {
                              setSelectedRow(item?.idEmployee);
                              handleContractTrainerListByTrainerModal(e);
                            }}
                          >
                            <i className="material-icons">receipt_long</i>
                          </button>
                          <button
                            title="Davamiyyət"
                            className="btn btn-warning"
                            onClick={(e) => {
                              setSelectedRow(item?.idEmployee);
                              handleAttendanceListModal(e);
                            }}
                          >
                            <i className="material-icons">event</i>
                          </button>
                          <Link to={`${alias}/trainer/view/${item?.idEmployee}`}>
                            <button className="btn btn-info" title="Baxış">
                              <i className="material-icons">visibility</i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
      {isOpenFinanceOperationListModal ? <FinanceOperationListModal isOpen={isOpenFinanceOperationListModal} handleViewModal={handleFinanceOperationListModal} header={"Təlimçi ödənişləri"} idFinanceOperationType={4} filter={{ idEmployee: selectedRow }} type={"trainer"} /> : null}
      {isContractTrainerListByTrainerModal ? <ContractTrainerListByTrainerModal isOpen={isContractTrainerListByTrainerModal} handleViewModal={handleContractTrainerListByTrainerModal} header={"Təlimçi müqavilələri"} trainerId={selectedRow} /> : null}
      {isOpenGroupListModal ? <GroupListForTrainerModal isOpen={isOpenGroupListModal} handleViewModal={handleGroupListModal} header={"Qruplar"} trainerId={selectedRow} /> : null}
      {isOpenAttendanceListModal ? <AttendanceListForTrainerModal isOpen={isOpenAttendanceListModal} handleViewModal={handleAttendanceListModal} header={"Davamiyyət"} trainerId={selectedRow} /> : null}
      {isOpenTrainerDebtListModal ? <TrainerDebtModal isOpen={isOpenTrainerDebtListModal} handleViewModal={handleTrainerDebtListModal} header={"Borclar"} trainerId={selectedRow} /> : null}
    </div>
  );
}
