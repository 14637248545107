import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../contexts/MainContext";
import Pagination from "./../../components/pagination/Index";
import * as studentService from "./../../services/StudentService";
import Loading from "./../../components/loading/Index";
import NotFound from "./../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";
import { Link } from "react-router-dom";
import * as Alerts from "./../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import PageSizeSelect from "./../../components/pageSizeSelect/Index";
import FinanceOperationListModal from "./../financeOperation/modal/ListModal";
import GroupListForStudentModal from "./../group/modal/GroupListForStudentModal";
import AttendanceListForStudentModal from "./../journal/modal/AttendanceListForStudentModal";
import StudentPassModal from "./modal/StudentPassModal";
import StudentDebtModal from "./modal/StudentDebtListModal";
import ContractStudentListByStudentModal from "./../contract/student/modal/ContractStudentListByStudentModal";
import ActionButtons from "./../../components/actionButton/Index";
import { URL } from "./../../services/core/Urls";

export default function Index() {
  const { state, setState } = useMainContext();
  const [students, setStudents] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ common: null });
  const [loading, setLoading] = useState(false);
  const [isOpenFinanceOperationListModal, setIsOpenFinanceOperationListModal] = useState(false);
  const [isOpenGroupListModal, setIsOpenGroupListModal] = useState(false);
  const [isOpenAttendanceListModal, setIsAttendanceListModal] = useState(false);
  const [isContractStudentListByStudentModal, setIsContractStudentListByStudentModal] = useState(false);

  const [isOpenStudentPassModal, setIsStudentPassModal] = useState(false);
  const [isOpenStudentDebtListModal, setIsOpenStudentDebtListModal] = useState(false);
  const [studentPass, setStudentPass] = useState({});

  const [isOpenCombo, setIsOpenCombo] = useState(false);
  const [isOpenFilterCombo, setIsOpenFilterCombo] = useState(false);

  const alias = useOrganization();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };

  const handleFinanceOperationListModal = (e) => {
    e.stopPropagation();
    if (isOpenFinanceOperationListModal) setSelectedRow(0);
    setIsOpenFinanceOperationListModal(!isOpenFinanceOperationListModal);
  };

  const handleGroupListModal = (e) => {
    e.stopPropagation();
    if (isOpenGroupListModal) setSelectedRow(0);
    setIsOpenGroupListModal(!isOpenGroupListModal);
  };

  const handleStudentDebtListModal = (e) => {
    e.stopPropagation();
    if (isOpenStudentDebtListModal) setSelectedRow(0);
    setIsOpenStudentDebtListModal(!isOpenStudentDebtListModal);
  };

  const handleAttendanceListModal = (e) => {
    e.stopPropagation();
    if (isOpenAttendanceListModal) setSelectedRow(0);
    setIsAttendanceListModal(!isOpenAttendanceListModal);
  };

  const handleStudentPassModal = (e) => {
    e.stopPropagation();
    if (isOpenStudentPassModal) setSelectedRow(0);
    setIsStudentPassModal(!isOpenStudentPassModal);
  };

  const handleContractStudentListByStudentModal = (e) => {
    e.stopPropagation();
    if (isContractStudentListByStudentModal) setSelectedRow(0);
    setIsContractStudentListByStudentModal(!isContractStudentListByStudentModal);
  };

  useEffect(() => {
    getStudents(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch, filter]);

  const getStudents = async (pageIndex, pageSize) => {
    setLoading(true);
    let result = await studentService.getStudentsByBranch(pageIndex, pageSize, localStorage.branch, filter);
    if (result.success) {
      setStudents(result.data.datas);
      setPagination({
        pageIndex: result.data.pageIndex,
        hasNextPage: result.data.hasNextPage,
        hasPreviousPage: result.data.hasPreviousPage,
        totalPageCount: result.data.totalPageCount,
        totalRecordCount: result.data.totalRecordCount,
      });
      setLoading(false);
    } else {
      Alerts.error(result.message);
    }
  };

  const deleteStudent = async (id) => {
    await Alerts.confirmForStudentDelete(async function (confirmed) {
      if (confirmed) {
        let result = await studentService.deleteStudent(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getStudents(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const changeActive = async (idStudent) => {
    let result = await studentService.changeActive(idStudent);
    if (result.success) {
      Alerts.success(result.message);
    } else {
      Alerts.error(result.message);
    }
    getStudents(pagination.pageIndex, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getStudents(pageIndex, localStorage.rowCount);
  };

  const Search = (e) => {
    e.preventDefault();
    getStudents(PAGE_INDEX, localStorage.rowCount);
  };

  const AddFilter = (filterName) => {
    setFilter({ common: filter.common, [filterName]: true });
    // getStudents(PAGE_INDEX, localStorage.rowCount);
  };

  const handleFileChange = async (e) => {
    await Alerts.confirmForImport(async function (confirmed) {
      if (confirmed) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        let result = await studentService.importStudentsbyExcel(formData, localStorage.branch);
        if (result.success) {
          getStudents(PAGE_INDEX, localStorage.rowCount);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
      }
    });
  };

  return (
    <div className="col-md-12">
      <label className="f-right">
        <div className={`dropdown ${isOpenCombo && "open"}`}>
          <button onClick={() => setIsOpenCombo(!isOpenCombo)} className="dropdown-toggle btn btn-success" data-toggle="dropdown">
            Digər <b className="caret" />
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/import") ? (
              <li>
                <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                  Məlumatların fayldan import et
                  <input type={"file"} accept=".xls, .xlsx" onChange={handleFileChange} onClick={(e) => (e.target.value = null)} style={{ display: "none" }} />
                </label>
              </li>
            ) : null}
            <li>
              <label className="btn btn-success" style={{ width: "-webkit-fill-available", margin: 5 }}>
                <a style={{ color: "white" }} onClick={() => (window.location = URL.BaseURL + `/student/export/${localStorage.branch}`)}>
                  Export
                </a>
              </label>
            </li>
          </ul>
        </div>
      </label>

      <ActionButtons deletePath={`student/delete`} editPath={`student/edit`} addPath={`student/add`} deleteRow={deleteStudent} selectedRow={selectedRow} />

      <label className="f-right">
        <div className={`dropdown ${isOpenFilterCombo && "open"}`}>
          <button onClick={() => setIsOpenFilterCombo(!isOpenFilterCombo)} className="dropdown-toggle btn open-blue" data-toggle="dropdown">
            Filter <b className="caret" />
          </button>
          <ul className="dropdown-menu dropdown-menu-left">
            <li>
              <a onClick={() => AddFilter("isAll")}>Hamısı</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isNotContract")}>Müqavilə bağlanmamış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isContract")}>Müqavilə bağlanmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isActiveContract")}>Aktiv müqaviləsi olan tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isIndividualContract")}>Fərdi təlim müqaviləsi olan tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isMoreOneActiveContract")}>Birdən artıq aktiv müqavilə bağlanmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isMoreOneContract")}>Birdən artıq müqavilə bağlanmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isStopedContract")}>Müqaviləsi dayandırılmış tələbələr</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isMoreOneGroup")}>Birdən artıq qrupda olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isNotAnyGroup")}>Heç bir qrupda olmayanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isLessOneGroup")}>Azı bir qrupda olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isBornToday")}>Bu gün doğum tarixi olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isBornTomorrow")}>Sabah doğum tarixi olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isBornWeek")}>Bu həftə doğum tarixi olanlar</a>
            </li>
            <li>
              <a onClick={() => AddFilter("isAddedThisMonth")}>Bu ay əlavə olanlar</a>
            </li>
          </ul>
        </div>
      </label>

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">person_pin</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Tələbələrin siyahısı</h4>
          <div className="row">
            <div className="col-md-6 f-left">
              <PageSizeSelect onChange={(e) => getStudents(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-6 f-right">
                <div className="form-group form-search navbar-form">
                  <input type="text" className="form-control" placeholder=" Axtar " value={filter?.common} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : students?.length > 0 ? (
            <div className="table-responsive">
              <div className="table-max-height">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Tam adı</th>
                      <th>FİN</th>
                      <th>Yaş</th>
                      <th>Əlaqə nömrəsi</th>
                      <th>Aktivlik</th>
                      <th className=" min-width-column"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((item, i) => (
                      <tr key={item?.idStudent} onClick={() => isSelectRow(item?.idStudent)} className={selectedRow === item?.idStudent ? "selectedRow" : ""}>
                        <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                        <td>{item?.surname + " " + item?.name + " " + (item?.middleName != null ? item?.middleName : "")}</td>
                        <td>{item?.id}</td>
                        <td>{item?.age}</td>
                        <td>{item?.phoneNumber}</td>
                        <td>
                          <div className="togglebutton">
                            <label>
                              <input type="checkbox" checked={item?.isActive} onChange={() => changeActive(item?.idStudent)} />
                              <span className="toggle" />
                            </label>
                          </div>
                        </td>
                        <td className="td-actions text-right min-width-column">
                          {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "student/profile") ? (
                            <button
                              title="Şəxsi kabinet"
                              className="btn btn-danger"
                              onClick={(e) => {
                                setSelectedRow(item?.idStudent);
                                setStudentPass({ id: item?.idStudent, pass: item?.password });
                                handleStudentPassModal(e);
                              }}
                            >
                              <i className="material-icons">key</i>
                            </button>
                          ) : null}
                          {-1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == "financeoperation/3") ? (
                            <button
                              title="Ödənişlər"
                              className="btn btn-success"
                              onClick={(e) => {
                                setSelectedRow(item?.idStudent);
                                handleFinanceOperationListModal(e);
                              }}
                            >
                              <i className="material-icons">paid</i>
                            </button>
                          ) : null}
                          <button
                            title="Borclar"
                            className="btn btn-danger"
                            onClick={(e) => {
                              setSelectedRow(item?.idStudent);
                              handleStudentDebtListModal(e);
                            }}
                          >
                            <i className="material-icons">payments</i>
                          </button>
                          <button
                            title="Qruplar"
                            className="btn btn-secondary"
                            onClick={(e) => {
                              setSelectedRow(item?.idStudent);
                              handleGroupListModal(e);
                            }}
                          >
                            <i className="material-icons">groups</i>
                          </button>
                          <button
                            title="Müqavilələr"
                            className="btn open-blue"
                            onClick={(e) => {
                              setSelectedRow(item?.idStudent);
                              handleContractStudentListByStudentModal(e);
                            }}
                          >
                            <i className="material-icons">receipt_long</i>
                          </button>
                          <button
                            title="Davamiyyət"
                            className="btn btn-warning"
                            onClick={(e) => {
                              setSelectedRow(item?.idStudent);
                              handleAttendanceListModal(e);
                            }}
                          >
                            <i className="material-icons">event</i>
                          </button>
                          <Link to={`${alias}/student/view/${item?.idStudent}`}>
                            <button className="btn btn-info" title="Baxış">
                              <i className="material-icons">visibility</i>
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
      {isOpenFinanceOperationListModal ? <FinanceOperationListModal isOpen={isOpenFinanceOperationListModal} handleViewModal={handleFinanceOperationListModal} header={"Tələbə ödənişləri"} idFinanceOperationType={3} filter={{ idStudent: selectedRow }} /> : null}
      {isOpenGroupListModal ? <GroupListForStudentModal isOpen={isOpenGroupListModal} handleViewModal={handleGroupListModal} header={"Qruplar"} studentId={selectedRow} /> : null}
      {isOpenAttendanceListModal ? <AttendanceListForStudentModal isOpen={isOpenAttendanceListModal} handleViewModal={handleAttendanceListModal} header={"Davamiyyət"} studentId={selectedRow} /> : null}
      {isOpenStudentPassModal ? <StudentPassModal isOpen={isOpenStudentPassModal} handleViewModal={handleStudentPassModal} header={"Şəxsi kabinet"} student={studentPass} alias={alias} /> : null}
      {isContractStudentListByStudentModal ? <ContractStudentListByStudentModal isOpen={isContractStudentListByStudentModal} handleViewModal={handleContractStudentListByStudentModal} header={"Tələbə müqavilələri"} studentId={selectedRow} /> : null}
      {isOpenStudentDebtListModal ? <StudentDebtModal isOpen={isOpenStudentDebtListModal} handleViewModal={handleStudentDebtListModal} header={"Borclar"} studentId={selectedRow} /> : null}

      {/* {isOpenViewModal ? <StudentViewModal isOpen={isOpenViewModal} handleViewModal={handleViewModal} student={student} /> : null} */}
    </div>
  );
}
