import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import Pagination from "./../../../components/pagination/Index";
import * as financeOperationService from "./../../../services/FinanceOperationService";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../../constants/Pagination";
import Loading from "./../../../components/loading/Index";
import NotFound from "./../../../components/notfound/Index";
import PageSizeSelect from "./../../../components/pageSizeSelect/Index";
import FinanceOperationAddModal from "./FinanceOperationAddModal";

import * as Alerts from "./../../../utils/Alerts";

export default function ListModal({ isOpen, handleViewModal, header, idFinanceOperationType, filter, type }) {
  const { state, setState } = useMainContext();
  const [financeOperations, setFinanceOperations] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenFinanceOperationAddModal, setIsOpenFinanceOperationAddModal] = useState(false);

  useEffect(() => {
    getFinanceOperation(PAGE_INDEX, localStorage.rowCount);
  }, []);

  const getFinanceOperation = async (pageIndex, pageSize) => {
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await financeOperationService.getFinanceOperationsByBranch(idFinanceOperationType, localStorage.branch, pageIndex, pageSize, filter);
      if (result.success) {
        setFinanceOperations(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const handleFinanceOperationAddModal = () => {
    isOpenFinanceOperationAddModal && getFinanceOperation(state.currentPage, localStorage.rowCount);
    setIsOpenFinanceOperationAddModal(!isOpenFinanceOperationAddModal);
  };

  const changePage = (pageIndex) => {
    getFinanceOperation(pageIndex, localStorage.rowCount);
  };

  return (
    <div className={isOpen ? "modal m-block" : "modal"} id="viewGroupModal" role="dialog" aria-labelledby="listGroupMemberModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content" data-background-color={state.theme}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ fontWeight: "600" }} id="listGroupMemberModalLabel">
              {header}
            </h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="card-content">
                  <PageSizeSelect onChange={(e) => getFinanceOperation(PAGE_INDEX, e.value)} />
                  {loading ? (
                    <Loading />
                  ) : financeOperations?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th>{idFinanceOperationType == 3 ? "Tələbə" : idFinanceOperationType == 4 ? "Təlimçi" : idFinanceOperationType == 5 ? "Əməkdaş" : idFinanceOperationType == 6 ? "Xərc adı" : idFinanceOperationType == 7 ? "Əlavə gəlir adı" : "Əməliyyat növü"}</th>
                            <th>{idFinanceOperationType == 3 || idFinanceOperationType == 4 ? "Müqavilə nömrəsi" : idFinanceOperationType == 5 ? `${type == "trainer" ? "Təlimçi" : "Əməkdaş"} ödəniş növü` : idFinanceOperationType == 6 ? "Xərc ödəniş növü" : ""}</th>
                            {idFinanceOperationType == 3 || idFinanceOperationType == 4 ? <th>Təlim növü</th> : null}
                            <th>Məbləğ</th>
                            <th>Tarix</th>
                          </tr>
                        </thead>
                        <tbody>
                          {financeOperations.map((item) => (
                            <tr key={item?.idFinanceOperation}>
                              <td className="text-center">{item?.idFinanceOperation}</td>
                              {idFinanceOperationType == 3 ? <td>{`${item?.student?.fullName} `}</td> : idFinanceOperationType == 4 || idFinanceOperationType == 5 ? <td>{`${item?.trainer?.surname} ${item?.trainer?.name} ${item?.trainer?.middleName} `}</td> : idFinanceOperationType == 6 ? <td>{item?.expense?.description}</td> : idFinanceOperationType == 7 ? <td>{item?.income?.description}</td> : <td>{item?.financeOperationType}</td>}
                              {idFinanceOperationType == 3 || idFinanceOperationType == 4 ? <td>{`${item?.contractNo != null ? item?.contractNo : ""} `}</td> : idFinanceOperationType == 5 ? <td>{`${item?.employeePaymentTypeName} `}</td> : idFinanceOperationType == 6 ? <td>{item?.expense?.expenseType}</td> : <td></td>}
                              {idFinanceOperationType == 3 || idFinanceOperationType == 4 ? <td>{item?.trainingType}</td> : null}
                              <td>{item?.amount}</td>
                              <td>{item?.financeOperationDate?.split("T")[0]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
                    </div>
                  ) : (
                    <NotFound />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {(idFinanceOperationType == 3 && -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == `financeoperation/3/add`)) || (idFinanceOperationType == 4 && -1 !== JSON.parse(localStorage?.menuPrivilages).findIndex((obj) => obj.menuName && obj.menuName.toLowerCase() == `financeoperation/4/add`)) ? (
              <button
                className="btn open-blue"
                onClick={(e) => {
                  handleFinanceOperationAddModal(e);
                }}
              >
                <i className="material-icons">paid</i> Yeni ödəniş
              </button>
            ) : null}
            <button className="btn btn-fill btn-danger" onClick={handleViewModal}>
              Bağla
            </button>
          </div>
        </div>
      </div>
      {isOpenFinanceOperationAddModal ? <FinanceOperationAddModal isOpen={isOpenFinanceOperationAddModal} handleViewModal={handleFinanceOperationAddModal} idFinanceOperationType={idFinanceOperationType} filter={filter} /> : null}
    </div>
  );
}
