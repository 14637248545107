import React, { useState, useEffect } from "react";
import { useMainContext } from "../../contexts/MainContext";
import Pagination from "../../components/pagination/Index";
import * as groupTrainingPlanService from "../../services/GroupTrainingPlanService";
import Loading from "../../components/loading/Index";
import NotFound from "../../components/notfound/Index";
import { PAGE_INDEX, PAGE_SIZE_COUNTS } from "../../constants/Pagination";

import * as Alerts from "../../utils/Alerts";
import { useOrganization } from "../../helper/useOrganization";
import { Link } from "react-router-dom";
import PageSizeSelect from "../../components/pageSizeSelect/Index";
import * as date from "../../utils/DateNow";
import { useTokenData } from "../../helper/useTokenData";
import { useStudent } from "../../helper/useStudent";
import ActionButtons from "../../components/actionButton/Index";
import * as UserTypes from "../../constants/UserType";

export default function Index() {
  const { state, setState } = useMainContext();
  const [groupPlanTrainings, setGroupPlanTrainings] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ startDate: date.Now(-30), endDate: date.Now() });

  const alias = useOrganization();
  const id = useStudent();
  const tokenData = useTokenData();

  const isSelectRow = (rowId) => {
    rowId == selectedRow ? setSelectedRow(0) : setSelectedRow(rowId);
  };


  useEffect(() => {
    setFilter({ startDate: date.Now(-30), endDate: date.Now() });
    getGroupTrainingPlans(state.currentPage, localStorage.rowCount);
  }, [localStorage.branch]);

  const getGroupTrainingPlans = async (pageIndex, pageSize) => {
    setLoading(true);
    if (localStorage.branch != undefined && localStorage.branch != "null") {
      let result = await groupTrainingPlanService.getGroupTrainingPlansByBranch(localStorage.branch, pageIndex, pageSize, filter);
      if (result.success) {
        setGroupPlanTrainings(result.data.datas);
        setPagination({
          pageIndex: result.data.pageIndex,
          hasNextPage: result.data.hasNextPage,
          hasPreviousPage: result.data.hasPreviousPage,
          totalPageCount: result.data.totalPageCount,
          totalRecordCount: result.data.totalRecordCount,
        });
        setLoading(false);
      } else {
        Alerts.error(result.message);
      }
    }
  };

  const deleteGetGroupTrainingPlan = async (id) => {
    await Alerts.confirmForDelete(async function (confirmed) {
      if (confirmed) {
        let result = await groupTrainingPlanService.deleteGroupTrainingPlan(id);
        if (result.success) {
          setSelectedRow(0);
          Alerts.success(result.message);
        } else {
          Alerts.error(result.message);
        }
        getGroupTrainingPlans(pagination.pageIndex, localStorage.rowCount);
      }
    });
  };

  const Search = (e) => {
    e.preventDefault();
    getGroupTrainingPlans(PAGE_INDEX, localStorage.rowCount);
  };

  const changePage = (pageIndex) => {
    setState({ ...state, currentPage: pageIndex });
    getGroupTrainingPlans(pageIndex, localStorage.rowCount);
  };

  return (
    <div className="col-md-12">
      {tokenData.userTypeId !=UserTypes.STUDENT_USER && (
      <ActionButtons 
      deletePath={`groupTrainingPlan/delete`}
      editPath={`groupTrainingPlan/edit`} 
      addPath={`groupTrainingPlan/add`}
      deleteRow={deleteGetGroupTrainingPlan}
       selectedRow={selectedRow} />   

      )}

      <div className="card" data-background-color={state.theme}>
        <div className="card-header card-header-icon" data-background-color={state.theme}>
          <i className="material-icons">app_registration</i>
        </div>
        <div className="card-content">
          <h4 className="card-title">Təlim planlarının siyahısı</h4>

          <div className="row">
            <div className="col-md-4 f-left">
              <PageSizeSelect onChange={(e) => getGroupTrainingPlans(PAGE_INDEX, e.value)} />
            </div>
            <form onSubmit={Search}>
              <div className="navbar-form col-md-2 f-right">
                <div className="form-group navbar-form">
                  <input type="date" className="form-control" value={filter?.startDate ?? ""} onChange={(e) => setFilter({ ...filter, startDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="date" className="form-control ml-10" value={filter?.endDate ?? ""} onChange={(e) => setFilter({ ...filter, endDate: e.target.value == "" ? null : e.target.value })} />
                  <input type="text" className="form-control ml-10" placeholder=" Axtar " value={filter?.common ?? ""} onChange={(e) => setFilter({ ...filter, common: e.target.value })} />
                  <span className="material-input" />
                </div>
                <button className="btn btn-white btn-round btn-just-icon" type="submit">
                  <i className="material-icons">search</i>
                  <div className="ripple-container" />
                </button>
              </div>
            </form>
          </div>

          {loading ? (
            <Loading />
          ) : groupPlanTrainings?.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Təlimçi</th>
                    <th>Başlıq</th>
                    <th>Məzmun</th>
                    <th>Plan tarixi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {groupPlanTrainings.map((item, i) => (
                    <tr key={item?.idGroupTrainingPlan} onClick={() => isSelectRow(item?.idGroupTrainingPlan)} className={selectedRow === item?.idGroupTrainingPlan ? "selectedRow" : ""}>
                      <td className="text-center">{(pagination.pageIndex - 1) * localStorage.rowCount + (i + 1)}</td>
                      <td>{item?.trainer?.fullName}</td>
                      <td>{item?.title}</td>
                      <td>{item?.description}</td>
                      <td>{item?.planDate}</td>
                      <td className="td-actions text-right">
                        <Link to={`${alias}${id}/groupTrainingPlan/view/${item?.idGroupTrainingPlan}`}>
                          <button className="btn btn-info">
                            <i className="material-icons">visibility</i>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pagination={pagination} changePage={(pageIndex) => changePage(pageIndex)} />
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
}
