import React from "react";

export default function TrAppealReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item.date.split("T")[0]}</td>
      <td>{item.count}</td>
      <td>{item.probability}</td>
      <td>{item.convertedToContract}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item.referance}</td>
      <td>{item.count}</td>
      <td>{item.probability}</td>
      <td>{item.convertedToContract}</td>
    </tr>
  ) : idReport == 3 ? (
    <tr>
      <td>{i}</td>
      <td>{item.groupType}</td>
      <td>{item.count}</td>
      <td>{item.probability}</td>
      <td>{item.convertedToContract}</td>
    </tr>
  ) : idReport == 4 ? (
    <tr>
      <td>{i}</td>
      <td>{item.trainer}</td>
      <td>{item.count}</td>
      <td>{item.probability}</td>
      <td>{item.convertedToContract}</td>
    </tr>
  ) : null;
}
