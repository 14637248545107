import React, { useState, useEffect } from "react";
import { useMainContext } from "./../../../contexts/MainContext";
import * as applicationService from "./../../../services/ApplicationService";
import * as groupService from "./../../../services/GroupService";
import * as trainingTypeService from "./../../../services/TrainingTypeService";
import * as employeeService from "./../../../services/EmployeeService";
import * as helperService from "./../../../services/HelperService";
import * as Alerts from "./../../../utils/Alerts";
import Select from "./../../../components/select/Index";
import FormInput from "./../../../components/formInput/Index";
import FormCheckbox from "./../../../components/formCheckbox/Index";
import { useOrganization } from "./../../../helper/useOrganization";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import CommitButton from "./../../../components/commitButton/Index";
import * as date from "./../../../utils/DateNow";

export default function Index() {
  const { state, setState } = useMainContext();
  const [isView, setIsView] = useState(false);
  const [application, setApplication] = useState({ applicationDate: date.Now(), applicationDeadline: date.Now(7) });
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [referances, setReferances] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [probability, setProbability] = useState(0);

  const alias = useOrganization();
  const location = useLocation();
  const navigate = useNavigate();
  const { idApplication } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/view/")) setIsView(true);
    idApplication && getApplication();
  }, []);

  useEffect(() => {
    getApplicationTypes();
    getReferances();
    getGroups();
    getTrainingTypes();
    getTrainers();
    getDivisions();
  }, [localStorage.branch]);

  const getApplication = async () => {
    let result = await applicationService.getApplication(idApplication);
    if (result.success) {
      setApplication(result.data);
      setProbability(result?.data?.probability)
    } else {
      Alerts.error(result.message);
    }
  };

  const getGroups = async () => {
    let result = await groupService.getForSelect(localStorage.branch);
    if (result.success) {
      setGroups(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getApplicationTypes = async () => {
    let result = await applicationService.getApplicationTypes();
    if (result.success) {
      setApplicationTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getReferances = async () => {
    let result = await applicationService.getReferances();
    if (result.success) {
      setReferances(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTrainingTypes = async () => {
    let result = await trainingTypeService.getTrainingTypes();
    if (result.success) {
      setTrainingTypes(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getDivisions = async () => {
    let result = await helperService.getDivisions();
    if (result.success) {
      setDivisions(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const getTrainers = async () => {
    let result = await employeeService.getForSelect();
    if (result.success) {
      setTrainers(result.data);
    } else {
      Alerts.error(result.message);
    }
  };

  const addOrUpdateApplication = async () => {
    let result = idApplication ? await applicationService.updateApplication({ ...application, fkIdBranch: localStorage.branch, probability }) : await applicationService.addApplication({ ...application, fkIdBranch: localStorage.branch, probability });
    if (result.success) {
      Alerts.successWithBack(result.message, `${alias}/application`, navigate);
    } else {
      Alerts.error(result.message);
    }
  };

  const handleInput = (e) => {
    setApplication({ ...application, [e.target.name]: e.target.value });
  };

  const handleSelect = (e, name) => {
    setApplication({ ...application, [name]: e.value });
  };

  return (
    <div class="content">
      <div class="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card" data-background-color={state.theme}>
              <div className="card-header card-header-icon" data-background-color={state.theme}>
                <i className="material-icons">assignment_ind</i>
              </div>
              <div className="card-content">
                <h4 className="card-title">{idApplication ? (isView ? "Müraciət məlumatları" : "Müraciət məlumatlarının redaktəsi") : "Yeni müraciət"}</h4>

                <div className="row">
                  <FormInput style={"col-sm-4"} label={"Ad"} name={"name"} required={true}  value={application?.name} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Soyad"} name={"surname"} required={true} value={application?.surname} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Ata adı"} name={"middleName"} value={application?.middleName} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Əlaqə nömrəsi"} name={"phoneNumber"} value={application?.phoneNumber} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Müraciət tarixi"} name={"applicationDate"} type={"date"} value={application?.applicationDate} onChange={handleInput} disabled={isView} />

                  <Select placeholder={"Seç"} style={"col-sm-4"} label={"Bölmə"} selectedValue={application?.fkIdDivision} changeSelectHandler={(e) => handleSelect(e, "fkIdDivision")} options={divisions.map((item) => ({ label: item?.description, value: item?.idDivision }))} isDisabled={isView} />

                  <FormInput style={"col-sm-4"} label={"Valideyn adı"} name={"parentName"}  value={application?.parentName} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Valideyn soyadı"} name={"parentSurname"} value={application?.parentSurname} onChange={handleInput} disabled={isView} />
                  <FormInput style={"col-sm-4"} label={"Valideyn ata adı"} name={"parentMiddleName"} value={application?.parentMiddleName} onChange={handleInput} disabled={isView} />
                 

                  <Select placeholder={"Seç"} style={"col-sm-4"} label={"Müraciət forması"} selectedValue={application?.fkIdApplicationType} changeSelectHandler={(e) => handleSelect(e, "fkIdApplicationType")} options={applicationTypes.map((item) => ({ label: item?.description, value: item?.idApplicationType }))} isDisabled={isView} />
                  <FormInput style={"col-sm-8"} label={"Müraciət forması qeydi"} name={"applicationTypeNote"} value={application?.applicationTypeNote} onChange={handleInput} disabled={isView} />
                  <Select placeholder={"Seç"} style={"col-sm-4"} label={"Referans"} selectedValue={application?.fkIdReferance} changeSelectHandler={(e) => handleSelect(e, "fkIdReferance")} options={referances.map((item) => ({ label: item?.description, value: item?.idReferance }))} isDisabled={isView} />
                  <FormInput style={"col-sm-8"} label={"Referans qeydi"} name={"referanceNote"} value={application?.referanceNote} onChange={handleInput} disabled={isView} />
                  <Select placeholder={"Seç"} style={"col-sm-4"} label={"Maraqlanılan kurs"} selectedValue={application?.fkIdTrainingType} changeSelectHandler={(e) => handleSelect(e, "fkIdTrainingType")} options={trainingTypes.map((item) => ({ label: item?.description, value: item?.idTrainingType }))} isDisabled={isView} />
                  <FormInput style={"col-sm-8"} label={"Maraqlanılan kurs qeydi"} name={"trainingTypeNote"} value={application?.trainingTypeNote} onChange={handleInput} disabled={isView} />
                  <Select placeholder={"Seç"} style={"col-sm-4"} label={"Maraqlanılan təlimçi"} selectedValue={application?.fkIdTrainer} changeSelectHandler={(e) => handleSelect(e, "fkIdTrainer")} options={trainers.map((item) => ({ label: item?.fullName, value: item?.idEmployee }))} isDisabled={isView} />
                  <FormInput style={"col-sm-8"} label={"Maraqlanılan təlimçi qeydi"} name={"trainerNote"} value={application?.trainerNote} onChange={handleInput} disabled={isView} />

                  <FormInput style={"col-sm-8"} label={"Müraciət barədə qeyd"} name={"note"} value={application?.note} onChange={handleInput} disabled={isView} />

                  <div className={"form-group is-empty col-sm-4"}>
                    <label className="control-label">Uğur ehtimalı</label>
                    <div>
                      <button onClick={() => setProbability(0)} className={`btn ${probability == 0 && "btn-success"} btn-round btn-fab btn-fab-mini`} disabled={isView}>
                        <span>0%</span>
                      </button>
                      <button onClick={() => setProbability(25)} className={`btn ${probability == 25 && "btn-success"} btn-round btn-fab btn-fab-mini`} disabled={isView}>
                        <span>25%</span>
                      </button>
                      <button onClick={() => setProbability(50)} className={`btn ${probability == 50 && "btn-success"} btn-round btn-fab btn-fab-mini`} disabled={isView}>
                        <span>50%</span>
                      </button>
                      <button onClick={() => setProbability(75)} className={`btn ${probability == 75 && "btn-success"} btn-round btn-fab btn-fab-mini`} disabled={isView}>
                        <span>75%</span>
                      </button>
                      <button onClick={() => setProbability(100)} className={`btn ${probability == 100 && "btn-success"} btn-round btn-fab btn-fab-mini`} disabled={isView}>
                        <span>100%</span>
                      </button>
                    </div>
                  </div>

                </div>

                <div className="row align-right">
                  {!isView && <CommitButton onClick={addOrUpdateApplication} />}
                  <Link to={`${alias}/application`}>
                    <button type="submit" className="btn btn-fill btn-danger">
                      Bağla
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
