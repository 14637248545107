import React from "react";

export default function TrTrainerReportItem({ idReport, item, i }) {
  return idReport == 1 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.fullName}</td>
      <td>{item?.contractCount}</td>
      <td>{item?.activeContractCount}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalDetailCount}</td>
    </tr>
  ) : idReport == 2 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.groupName}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.groupPlanDays}</td>
      <td>{item?.groupPlanCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalParticipatedCount}</td>
      <td>{item?.trainingMaterialCount}</td>
    </tr>
  ): idReport == 5 ? (
    <tr>
      <td>{i}</td>
      <td>{item?.trainerFullName}</td>
      <td>{item?.groupCount}</td>
      <td>{item?.groupMemberCount}</td>
      <td>{item?.trainingCount}</td>
      <td>{item?.journalCount}</td>
      <td>{item?.journalParticipateCount}</td>
    </tr>
  ) : null;
}
