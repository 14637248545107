import React, { useState, useEffect } from "react";
import * as authService from "./../../services/AuthService";
import * as userService from "./../../services/UserService";
import jwt_decode from "jwt-decode";
import logo from "./../../assets/logo/edu_w.png";
import Loading from "./../../components/loading/Index";
import * as organizationService from "./../../services/OrganizationService";
import { useLocation, useParams } from "react-router-dom";
import { useMainContext } from "./../../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { BaseAttachURL, FileRoutes } from "./../../services/core/Urls";
import { useTokenData } from "../../helper/useTokenData";
import * as UserTypes from "./../../constants/UserType";

export default function Index() {
  const navigate = useNavigate();
  const tokenData = useTokenData();
  const location = useLocation();

  const { state, setState } = useMainContext();

  const { alias, studentId } = useParams();
  const [loginData, setLoginData] = useState({ alias, username: isNaN(+studentId) || studentId == "0" ? "" : studentId });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [aliasData, setAliasData] = useState("");
  useEffect(() => {
    if (localStorage.token != null) {
      let goToPath = "https://app.e-edu.az/";
     // let goToPath = "http://localhost:3000/";
      if (tokenData?.isSysUser == "1" && location?.pathname?.toLowerCase().includes("admin")) window.location = goToPath + "organization";
      else if (localStorage.studentId != null && location?.pathname?.toLowerCase().includes(`${alias}/${localStorage.studentId}`)) window.location = goToPath + `${alias}/${localStorage.studentId}/dashboard`;
      else if (tokenData?.userTypeId == UserTypes.ORGANIZATION_USER  && alias?.toLowerCase() != "admin" && isNaN(+studentId) && location?.pathname?.toLowerCase().includes(`${alias}`)) window.location = goToPath + `${alias}/dashboard`;
      else if (tokenData?.userTypeId == UserTypes.TRAINER_USER  && isNaN(+studentId) && location?.pathname?.toLowerCase().includes(`${alias}`)) window.location = goToPath + `${alias}/dashboard`;
    }
    checkAlias();
  }, []);

  const checkAlias = async () => {
      let result = await organizationService.checkAlias(alias);
      if (result.success) {
        setAliasData(result.data);
        // localStorage.logo = "data:image/jpg;base64," + result?.data?.logo;
        localStorage.logo = BaseAttachURL + FileRoutes.OrganizationRoute + result?.data?.logo;
        document.title = result?.data?.organizationName;
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href = localStorage.logo;
    }
  };

  const login = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!isNaN(+studentId) && studentId != "0" && studentId != loginData.username) {
      setError(`Məlumatlar düzgün daxil edilməyib`);
    } else {
      let result = !isNaN(+studentId) || studentId == "0" ? await authService.loginStudent(loginData) : await authService.loginUser(loginData);
      if (result.success) {
        localStorage.token = result.data.token;
        localStorage.rowCount = result.data?.user?.tableRowCount;
        localStorage.orgName = result.data?.user?.organizationName;
        localStorage.userName = result.data?.user?.userName;
        localStorage.userType = result.data?.user?.userType;
        localStorage.branch = result.data?.activeBranchId;
        if (!isNaN(+studentId)) localStorage.studentId = loginData.username;
        localStorage.logo = BaseAttachURL + FileRoutes.OrganizationRoute + result.data?.logo;
        let decoded = jwt_decode(localStorage.token);
        // navigate(`/${decoded.alias}/dashboard`);
        window.location = isNaN(+studentId) ? `/${decoded.alias}/dashboard` : `/${decoded.alias}/${loginData.username}/dashboard`;
      } else {
        setError(result.message);
        // Alerts.error(result.message);
      }
    }
    setLoading(false);
  };

  const handleInput = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  return (
    <div className="full-page login-page" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="content">
        <div className="container">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form onSubmit={login}>
              <div className="card card-login" data-background-color={"blue"}>
                <div className="card-header text-center" data-background-color={"blue"}>
                  {alias != "student" ? (
                    <>
                      <img style={{ maxWidth: 300 }} src={BaseAttachURL + FileRoutes.OrganizationRoute + aliasData?.logo} />
                      {(aliasData?.logo == null || aliasData?.logo == "") && <h5 className="card-title">{aliasData.organizationName}</h5>}
                    </>
                  ) : (
                    <h5 className="card-title">{"E-EDU.AZ"}</h5>
                  )}
                </div>
                <div className="card-content">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">face</i>
                    </span>
                    <div className="form-group label-floating is-empty">
                      <label>İstifadəçi adı</label>
                      <input autoFocus type="text" name="username" value={loginData?.username} className="form-control" onChange={handleInput} />
                      <span className="material-input" />
                    </div>
                  </div>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">lock_outline</i>
                    </span>
                    <div className="form-group label-floating is-empty">
                      <label>Şifrə</label>
                      <input type="password" className="form-control" name="password" onChange={handleInput} />
                      <span className="material-input" />
                    </div>
                  </div>
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>

                <div className="footer text-center">
                  <button type="submit" className="btn btn-info btn-wd">
                    {loading ? <Loading type={"spinningBubbles"} color={"white"} height={25} width={25} /> : "Daxil ol"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
